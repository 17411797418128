import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Container} from 'react-bootstrap';
import Swal from 'sweetalert2';
const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB

const FormKeanggotaanPerusahaan = ({ uid, username }) => {

    const [formData, setFormData] = useState({
        komisaris0: '',
        komisaris1: '',
        komisaris2: '',
        direktur0: '',
        direktur1: '',
        direktur2: '',
        saham1: '',
        saham2: '',
        saham3: '',
        email_dirut: '',
        hp_dirut: '',
        ktp_dirut: '',
        npwp_dirut: '',
        nama_pic: '',
        jabatan_pic: '',
        hp_pic: '',
        foto_ktp: null,
        foto_npwp: null
    });

    const [IsFileRequired, setIsFileRequired] = useState(true); 
    const [disabledForm, setdisabledForm] = useState(false); 

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getKeanggotaanPerusahaan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            //console.log('Fetched data:', data[0]);
            setFormData({
                komisaris0: data[0].komisaris_utama || '',
                komisaris1: data[0].komisaris_1 || '',
                komisaris2: data[0].komisaris_2 || '',
                direktur0: data[0].direktur_utama || '',
                direktur1: data[0].direktur_1 || '',
                direktur2: data[0].direktur_2 || '',
                saham1: data[0].pemegang_saham_1 || '',
                saham2: data[0].pemegang_saham_2 || '',
                saham3: data[0].pemegang_saham_3 || '',
                email_dirut: data[0].email_dirut || '',
                hp_dirut: data[0].nomor_telepon_dirut || '',
                ktp_dirut: data[0].ktp_dirut || '',
                npwp_dirut: data[0].npwp_dirut || '',        
                nama_pic: data[0].nama_pic || '',        
                jabatan_pic: data[0].jabatan_pic || '',        
                hp_pic: data[0].hp_pic || ''            
            });
            if(data.length > 0){
                setIsFileRequired(false);
                
                if(data[0].status=='PENDING' || data[0].status=='APPROVED' || data[0].status=='REJECTED'){
                    setdisabledForm(true); // Menjadikan Logo tidak wajib jika sudah ada
                }
                if(uid==132 || uid>150){
                    setdisabledForm(false); // Menjadikan Logo tidak wajib jika sudah ada
                }
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if ((name === 'foto_ktp' || name === 'foto_npwp') && files.length > 0) {
            const file = files[0];
            if (file.type !== 'image/jpeg') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: 'Hanya file .jpg yang diperbolehkan!',
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                });
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const usernamePart = `${uid}_${username.split('@')[0]}`;

        const requiredFiles = [
            'foto_ktp',
            'foto_npwp'
        ];

        for (const fileName of requiredFiles) {
            if (!formData[fileName] && IsFileRequired==true) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: `Anda belum memilih ${fileName.replace('foto_', '').replace('_', ' ')}!`,
                });
                return; // Stop form submission
            }
        }


        // Create FormData object
        const data = new FormData();
        data.append('username', username);
        data.append('komisaris0', formData.komisaris0);
        data.append('komisaris1', formData.komisaris1);
        data.append('komisaris2', formData.komisaris2);
        data.append('direktur0', formData.direktur0);
        data.append('direktur1', formData.direktur1);
        data.append('direktur2', formData.direktur2);
        data.append('saham1', formData.saham1);
        data.append('saham2', formData.saham2);
        data.append('saham3', formData.saham3);
        data.append('email_dirut', formData.email_dirut);
        data.append('hp_dirut', formData.hp_dirut);
        data.append('ktp_dirut', formData.ktp_dirut);
        data.append('npwp_dirut', formData.npwp_dirut);
        data.append('nama_pic', formData.nama_pic);
        data.append('jabatan_pic', formData.jabatan_pic);

        if(formData.hp_pic.startsWith('0852') || formData.hp_pic.startsWith('0853') || formData.hp_pic.startsWith('0811') || 
        formData.hp_pic.startsWith('0812') || formData.hp_pic.startsWith('0813') || formData.hp_pic.startsWith('0821') ||
         formData.hp_pic.startsWith('0822') || formData.hp_pic.startsWith('0823') || formData.hp_pic.startsWith('0851') ){
            data.append('hp_pic', formData.hp_pic);
        }else{
            Swal.fire({
                icon: 'warning',
                title: 'Peringatan',
                text: `NO HP PIC Harus menggunakan nomor TELKOMSEL!`,
            });
            return; // Stop form submission
        }

        if (formData.foto_ktp) {
            const renamedFile = new File([formData.foto_ktp], `${usernamePart}_ktp.jpg`, {
                type: formData.foto_ktp.type,
            });
            data.append('foto_ktp', renamedFile);
        }
        if (formData.foto_npwp) {
            const renamedFile = new File([formData.foto_npwp], `${usernamePart}_npwp.jpg`, {
                type: formData.foto_npwp.type,
            });
            data.append('foto_npwp', renamedFile);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/insertKeanggotaanPerusahaan`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Sukses',
                    text: 'Data perusahaan berhasil disimpan',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: `Terjadi kesalahan saat menyimpan data perusahaan: ${error.message}`,
            });
        }
    };

    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <p className="mb-4"><b>Keanggotaan Perusahaan</b></p>            
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>NAMA ANGGOTA KOMISARIS UTAMA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="Mandatory" style={{ width: '90%' }} name="komisaris0" value={formData.komisaris0} required onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>NAMA ANGGOTA KOMISARIS 1</span>
                            <Form.Control size="sm" type="text" placeholder="Optional" style={{ width: '90%' }} name="komisaris1" value={formData.komisaris1} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>NAMA ANGGOTA KOMISARIS 2</span>
                            <Form.Control size="sm" type="text" placeholder="Optional" style={{ width: '90%' }} name="komisaris2" value={formData.komisaris2} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>NAMA DIREKTUR UTAMA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="Mandatory" style={{ width: '90%' }} required name="direktur0" value={formData.direktur0} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>NAMA DIREKTUR 1 </span>
                            <Form.Control size="sm" type="text" placeholder="Optional" style={{ width: '90%' }} name="direktur1" value={formData.direktur1} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>NAMA DIREKTUR 2 </span>
                            <Form.Control size="sm" type="text" placeholder="Optional" style={{ width: '90%' }} name="direktur2" value={formData.direktur2} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>PEMEGANG SAHAM 1 <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="Mandatory" style={{ width: '90%' }} required name="saham1" value={formData.saham1} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>PEMEGANG SAHAM 2</span>
                            <Form.Control size="sm" type="text" placeholder="Optional" style={{ width: '90%' }} name="saham2" value={formData.saham2} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>PEMEGANG SAHAM 3</span>
                            <Form.Control size="sm" type="text" placeholder="Optional" style={{ width: '90%' }} name="saham3" value={formData.saham3} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>NAMA PIC <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="Mandatory" style={{ width: '90%' }} required name="nama_pic" value={formData.nama_pic} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>JABATAN PIC<span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="Mandatory" style={{ width: '90%' }} required name="jabatan_pic" value={formData.jabatan_pic} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>NO HP PIC<span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="number" placeholder="Mandatory (no telkomsel)" style={{ width: '90%' }} required name="hp_pic" value={formData.hp_pic} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                    </Row>
                </Container>
                <p className="mb-4"><b>Informasi Perusahaan</b></p>

                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>EMAIL DIREKTUR UTAMA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="email" placeholder="Mandatory" style={{ width: '95%' }} required name="email_dirut" value={formData.email_dirut} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>NO HP DIREKTUR UTAMA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="number" placeholder="Mandatory" style={{ width: '95%' }} required name="hp_dirut" value={formData.hp_dirut} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>KTP DIREKTUR UTAMA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="number" placeholder="Mandatory" style={{ width: '95%' }} required name="ktp_dirut" value={formData.ktp_dirut} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>NPWP DIREKTUR UTAMA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="number" placeholder="Mandatory" style={{ width: '95%' }} required name="npwp_dirut" value={formData.npwp_dirut} onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col xs={6} className="px-0">
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="foto_ktp" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 5 MB | file .JPG</p>
                        </Col>
                        <Col xs={6} className="p-0">
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="foto_npwp" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 5 MB | file .JPG</p>
                        </Col>
                        <Col xs={12} className="pt-4 px-4 text-end">
                            <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>

            </Form>
        </div>
    );
}

export default FormKeanggotaanPerusahaan;
