import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Alert, Form, Button, Row, Col, Container } from 'react-bootstrap';

const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB

const FormDokumenSwitching_1 = ({ uid, tipe, username }) => {
    const [formData, setFormData] = useState({
        koneksi_isp: null,
        topologi_jaringan: null,
        network_monitoring: null,
        disaster_recovery: null
    });

    const [IsFileRequired, setIsFileRequired] = useState(true); 
    const [disabledForm, setdisabledForm] = useState(false); 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getDokumenSwitching1`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            setdisabledForm(true); // DISABLE WEB
            if(uid==132 || uid>150){
                setdisabledForm(false); // Menjadikan Logo tidak wajib jika sudah ada
            }
            if(data.length > 0){
                setIsFileRequired(false);
               /* if(data[0].status=='PENDING' || data[0].status=='APPROVED'){
                        setdisabledForm(true); // Menjadikan Logo tidak wajib jika sudah ada
                }*/
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, files } = e.target;

        if ((name === 'koneksi_isp' || name === 'topologi_jaringan' || name === 'network_monitoring' || name === 'disaster_recovery') && files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/pdf') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: 'Hanya file .pdf yang diperbolehkan!',
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                });
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requiredFiles = [
            'koneksi_isp',
            'topologi_jaringan',
            'network_monitoring',
            'disaster_recovery',
        ];

        for (const fileName of requiredFiles) {
            if (!formData[fileName] && IsFileRequired==true) {
                Swal.fire({
                    icon: 'warning',
                    title: 'File tidak lengkap',
                    text: `File ${fileName} harus diunggah!`,
                });
                setLoading(false);
                return;
            }
        }

        const usernamePart = `${uid}_${username.split('@')[0]}`;
        const data = new FormData();
        data.append('username', username);
        data.append('tipe', tipe);

        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                const renamedFile = new File([formData[key]], `${usernamePart}_${key}.pdf`, {
                    type: formData[key].type,
                });
                data.append(key, renamedFile);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/uploadDokumenSwitching_1`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Data berhasil disimpan',
                    text: 'Data perusahaan berhasil disimpan',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal menyimpan data',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
            });
        }
        setLoading(false);
    };

    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <p className="mb-4"><b>INFRASTRUKTUR</b></p>     
            <Alert key="info" variant="info">Semua file yang di upload harus berformat <b>.pdf</b> dengan max size per file 10Mb</Alert>       
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>KONEKSI DENGAN MINIMUM 1 ISP <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="koneksi_isp" onChange={handleChange} disabled={disabledForm} />
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>JARINGAN PENDUKUNG SISTEM (INCLUDE TOPOLOGI JARINGAN) <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="topologi_jaringan" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                    <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>NETWORKING MONITORING SISTEM <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="network_monitoring" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>DISASTER RECOVERY CENTER <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="disaster_recovery" onChange={handleChange} disabled={disabledForm} />
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={12} className="pt-4 px-4 text-end">
                            <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
                {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}
            </Form>
        </div>
    );
}

export default FormDokumenSwitching_1;
