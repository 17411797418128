import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const MAX_FILE_SIZE_MB = 20; // Maximum file size in MB


const FormUploadPKS = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [isPdf, setIsPdf] = useState(false);

    const { pks_id, nama_perusahaan } = useParams();

    const [formData, setFormData] = useState({
        no_pks: '',
        tahun_pks: '',
        pks_id: '',
        nama_perusahaan: '',
        file_no_pks: null,
        lampiran_1: null,
        lampiran_2: null,
        lampiran_3: null,
        lampiran_4: null,
        lampiran_4_reduce: null,
        lampiran_4_expand: null,
        lampiran_5: null
    });
    
   useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/FormReviewPKS`, {
                    params: { pks_id }
                });
                setData(response.data);
                setFormData({
                    no_pks: response.data.no_pks,
                });
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('An error occurred while fetching data');
                setLoading(false);
            }
        };

        fetchData();
    }, [pks_id]);

    /*useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/FormReviewPKS`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pks_id })
        })
        .then(response => response.json())
        .then(data => {
            setFormData({
                nama_perusahaan: data[0].nama_perusahaan || '',
                no_pks: data[0].no_pks || '',
                tahun_pks: data[0].tahun_pks || '',
            });

        })
        .catch(error => console.error('Error fetching data:', error));
    }, [pks_id]);*/

    
    
    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if ((name === 'file_no_pks' || name === 'lampiran_1' || name === 'lampiran_2' || name === 'lampiran_3' || name === 'lampiran_4' || name === 'lampiran_4_reduce' || name === 'lampiran_4_expand' || name === 'lampiran_5') && files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/pdf') {
                Swal.fire({
                    icon: 'error',
                    title: 'File Tidak Valid',
                    text: 'Hanya file .pdf yang diperbolehkan!',
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ukuran File Terlalu Besar',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                });
                return;
            }
            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };
    

    const handleSwitch = (e) => {
        const { name, value, files } = e.target;
        if (name === 'tahun_pks') {
            setFormData(prevState => ({
                ...prevState,
                tahun_pks: value,
                pks_id: '' // Reset namaPerusahaan
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Extract username before '@'
        const filenamePart = `${pks_id}_${nama_perusahaan}`;

        // Create FormData object

        const data = new FormData();
        data.append('no_pks', formData.no_pks);
        data.append('pks_id', pks_id);


        if (formData.file_no_pks) {
            const renamedFile = new File([formData.file_no_pks], `${filenamePart}_pks.pdf`, {
                type: formData.file_no_pks.type,
            });
            data.append('file_no_pks', renamedFile);
        }
        if (formData.lampiran_1) {
            const renamedFile = new File([formData.lampiran_1], `${filenamePart}_lampiran_1.pdf`, {
                type: formData.lampiran_1.type,
            });
            data.append('lampiran_1', renamedFile);
        }
        if (formData.lampiran_2) {
            const renamedFile = new File([formData.lampiran_2], `${filenamePart}_lampiran_2.pdf`, {
                type: formData.lampiran_2.type,
            });
            data.append('lampiran_2', renamedFile);
        }
        if (formData.lampiran_3) {
            const renamedFile = new File([formData.lampiran_3], `${filenamePart}_lampiran_3.pdf`, {
                type: formData.lampiran_3.type,
            });
            data.append('lampiran_3', renamedFile);
        }
        if (formData.lampiran_4) {
            const renamedFile = new File([formData.lampiran_4], `${filenamePart}_lampiran_4.pdf`, {
                type: formData.lampiran_4.type,
            });
            data.append('lampiran_4', renamedFile);
        }
        if (formData.lampiran_4_reduce) {
            const renamedFile = new File([formData.lampiran_4_reduce], `${filenamePart}_lampiran_4_reduce.pdf`, {
                type: formData.lampiran_4_reduce.type,
            });
            data.append('lampiran_4', renamedFile);
        }
        if (formData.lampiran_4_expand) {
            const renamedFile = new File([formData.lampiran_4_expand], `${filenamePart}_lampiran_4_expand.pdf`, {
                type: formData.lampiran_4_expand.type,
            });
            data.append('lampiran_4_expand', renamedFile);
        }
        if (formData.lampiran_5) {
            const renamedFile = new File([formData.lampiran_5], `${filenamePart}_lampiran_5.pdf`, {
                type: formData.lampiran_5.type,
            });
            data.append('lampiran_5', renamedFile);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/insertPKS`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Sukses!',
                    text: 'Data perusahaan berhasil disimpan',
                    confirmButtonText: 'OK',
                    confirmButtonColor: 'green'
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal!',
                    text: 'Gagal menyimpan data perusahaan',
                    confirmButtonText: 'Kembali',
                    confirmButtonColor: '#d33'
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
                confirmButtonText: 'Kembali',
                confirmButtonColor: '#d33'
            });
        }
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
            <div style={{ height: '70vh', overflowY: 'auto' }}>           
                <Form onSubmit={handleSubmit}>                   
                    <p className="mb-4"><b>INFORMASI PERUSAHAAN</b></p>
                    <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                         <Row className='pb-2'>
                            <Col xs={6} className="px-0">
                                <span style={{ fontSize: '12px' }}>NAMA PERUSAHAAN <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="text" style={{ width: '95%' }} disabled name="nama_perusahaan" value={data.nama_perusahaan}/>
                            </Col>
                            <Col xs={6} className="p-0">
                                <span style={{ fontSize: '12px' }}>TAHUN NGPP <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="text" style={{ width: '95%' }} disabled name="tahun_pks"  value={data.tahun_pks}/>
                            </Col>
                        </Row>
                    </Container>
                    <p className="mb-4"><b>UPLOAD FILE PKS & LAMPIRAN</b></p>    
                    <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">    
                        <Row className='pb-2'>
                            <Col xs={3} className="px-0">
                                <span style={{ fontSize: '12px' }}>NOMOR PKS <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="text" placeholder="Mandatory" style={{ width: '95%' }} required name="no_pks" value={formData.no_pks} onChange={handleChange} />
                            </Col>
                            <Col xs={3} className="px-0">
                                <span style={{ fontSize: '12px' }}> FILE PKS<span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="file_no_pks" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>                                
                            </Col>
                            
                            <Col xs={6} className="p-0">
                                <span style={{ fontSize: '12px' }}>LAMPIRAN 1 <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="lampiran_1" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>
                            </Col>
                        </Row>
                        <Row className='pb-2'>
                            <Col xs={6} className="px-0">
                                <span style={{ fontSize: '12px' }}>LAMPIRAN 2 <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="lampiran_2" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>
                            </Col>
                            <Col xs={6} className="p-0">
                                <span style={{ fontSize: '12px' }}>LAMPIRAN 3 <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="lampiran_3" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>
                            </Col>
                        </Row>
                        <Row className='pb-2'>
                            <Col xs={6} className="px-0">
                                 <span style={{ fontSize: '12px' }}>LAMPIRAN 4 <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="lampiran_4" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>
                            </Col>
                            <Col xs={6} className="p-0">
                                 <span style={{ fontSize: '12px' }}>LAMPIRAN 4 REDUCE <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="lampiran_4_reduce" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>
                            </Col>
                        </Row>
                        <Row className='pb-2'>
                            <Col xs={6} className="px-0">
                                 <span style={{ fontSize: '12px' }}>LAMPIRAN 4 EXPAND <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="lampiran_4_expand" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>
                            </Col>
                            <Col xs={6} className="p-0">
                                 <span style={{ fontSize: '12px' }}>LAMPIRAN 5 (BERITA ACARA/KOMITMEN) <span className="text-danger"><b></b></span></span>
                                <Form.Control size="sm" type="file" style={{ width: '95%' }}  name="lampiran_5" onChange={handleChange} />
                                <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 20 MB | file .PDF</p>
                            </Col>
                            <Col xs={12} className="pt-4 px-4 text-end">
                                <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
        </div>
    );
}

export default FormUploadPKS;