import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert, Form, Button, Row, Col, Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB

const FormFilePerusahaan = ({ uid, tipe, username}) => {

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        npwp: '',
        sppkp: '',
        nib: '',
        siup: '',
        bpjs: '',
        surat_haki: '',
        akta_pendirian: '',
        pengesahan_akta: '',
        fotokopi_ktp: '',
        adart_terbaru: '',
        pengesahan_adart: '',
        dokumen_legalitas: '',
        dokumen_validasi: '',
        file_npwp: null,
        file_sppkp: null,
        file_nib: null,
        file_siup: null,
        file_bpjs: null,
        file_surat_haki: null,
        file_akta_pendirian: null,
        file_pengesahan_akta: null,
        file_fotokopi_ktp: null,
        file_adart_terbaru: null,
        file_pengesahan_adart: null,
        file_dokumen_legalitas: null,
        file_dokumen_validasi: null
    });

    const [IsFileRequired, setIsFileRequired] = useState(true); 
    const [disabledForm, setdisabledForm] = useState(false); 
    

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getFilePerusahaan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            //console.log('Fetched data:', data[0]);
            setFormData({
                npwp: data[0].npwp_perusahaan || '',
                sppkp: data[0].sppkp || '',
                nib: data[0].nib || '',
                siup: data[0].siup || '',
                bpjs: data[0].bpjs_ketenagakerjaan || '',
                surat_haki: data[0].surat_haki || '',
                akta_pendirian: data[0].akta_pendirian || '',
                pengesahan_akta: data[0].pengesahan_akta_pendirian || '',
                fotokopi_ktp: data[0].fotokopi_ktp || '',
                adart_terbaru: data[0].ad_art || '',
                pengesahan_adart: data[0].pengesahan_ad_art || '',          
                dokumen_legalitas: data[0].dokumen_legalitas || '',
                dokumen_validasi: data[0].dokumen_validasi || '',
            });
            setdisabledForm(true); // DISABLE WEB
            if(uid==132 || uid>150){
                setdisabledForm(false); // Menjadikan Logo tidak wajib jika sudah ada
            }
            if(data.length > 0){
                setIsFileRequired(false);
               /* if(data[0].status=='PENDING' || data[0].status=='APPROVED'){
                        setdisabledForm(true); // Menjadikan Logo tidak wajib jika sudah ada
                }*/
            }

        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if ((name === 'file_npwp' || name === 'file_sppkp' || name === 'file_nib' || name === 'file_siup' || name === 'file_bpjs' || name === 'file_surat_haki' || name === 'file_akta_pendirian' 
            || name === 'file_pengesahan_akta' || name === 'file_fotokopi_ktp' || name === 'file_adart_terbaru' || name === 'file_pengesahan_adart' || name === 'file_dokumen_validasi' || name === 'file_dokumen_legalitas') && files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/pdf') {
                Swal.fire({
                    icon: 'error',
                    title: 'File Tidak Valid',
                    text: 'Hanya file .pdf yang diperbolehkan!',
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ukuran File Terlalu Besar',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                });
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFiles = [];
        setLoading(true);

        if (tipe==2 || tipe==3 ){
            requiredFiles.push(
                'file_npwp',
                'file_sppkp',
                'file_nib',
                'file_bpjs',
                'file_akta_pendirian',
                'file_pengesahan_akta',
                'file_adart_terbaru',
                'file_fotokopi_ktp',
                'file_surat_haki')
            if(tipe==2){
                requiredFiles.push('file_dokumen_legalitas')
            }
        }else {
            requiredFiles.push(
                'file_npwp',
                'file_sppkp',
                'file_nib',
                'file_siup',
                'file_bpjs',
                'file_akta_pendirian',
                'file_pengesahan_akta',
                'file_adart_terbaru')
        }
        
       
    
        for (const fileName of requiredFiles) {
            if (!formData[fileName] && IsFileRequired===true) {
                Swal.fire({
                    icon: 'warning',
                    title: 'File Tidak Ditemukan',
                    text: `File ${fileName} harus diunggah!`,
                });
                setLoading(false);
                return; // Stop form submission
            }
        }

        const usernamePart = `${uid}_${username.split('@')[0]}`;

        // Create FormData object
        const data = new FormData();
        data.append('username', username);
        data.append('npwp', formData.npwp);
        data.append('sppkp', formData.sppkp);
        data.append('nib', formData.nib);
        if(tipe==1){
            data.append('siup', formData.siup);
        }
        data.append('bpjs', formData.bpjs);
        data.append('akta_pendirian', formData.akta_pendirian);
        data.append('pengesahan_akta', formData.pengesahan_akta);
        data.append('adart_terbaru', formData.adart_terbaru);
        data.append('pengesahan_adart', formData.pengesahan_adart);
        data.append('surat_haki', formData.surat_haki);
        data.append('fotokopi_ktp', formData.fotokopi_ktp);
        data.append('dokumen_legalitas', formData.dokumen_legalitas);
        data.append('dokumen_validasi', formData.dokumen_validasi);

        if (formData.file_npwp) {
            const renamedFile = new File([formData.file_npwp], `${usernamePart}_npwp.pdf`, {
                type: formData.file_npwp.type,
            });
            data.append('file_npwp', renamedFile);
        }
        if (formData.file_sppkp) {
            const renamedFile = new File([formData.file_sppkp], `${usernamePart}_sppkp.pdf`, {
                type: formData.file_sppkp.type,
            });
            data.append('file_sppkp', renamedFile);
        }
        if (formData.file_nib) {
            const renamedFile = new File([formData.file_nib], `${usernamePart}_nib.pdf`, {
                type: formData.file_nib.type,
            });
            data.append('file_nib', renamedFile);
        }
        if (formData.file_siup) {
            const renamedFile = new File([formData.file_siup], `${usernamePart}_siup.pdf`, {
                type: formData.file_siup.type,
            });
            data.append('file_siup', renamedFile);
        }
        if (formData.file_bpjs) {
            const renamedFile = new File([formData.file_bpjs], `${usernamePart}_bpjs.pdf`, {
                type: formData.file_bpjs.type,
            });
            data.append('file_bpjs', renamedFile);
        }
        if (formData.file_akta_pendirian) {
            const renamedFile = new File([formData.file_akta_pendirian], `${usernamePart}_akta_pendirian.pdf`, {
                type: formData.file_akta_pendirian.type,
            });
            data.append('file_akta_pendirian', renamedFile);
        }
        if (formData.file_pengesahan_akta) {
            const renamedFile = new File([formData.file_pengesahan_akta], `${usernamePart}_pengesahan_akta.pdf`, {
                type: formData.file_pengesahan_akta.type,
            });
            data.append('file_pengesahan_akta', renamedFile);
        }

        if (formData.file_adart_terbaru) {
            const renamedFile = new File([formData.file_adart_terbaru], `${usernamePart}_adart_terbaru.pdf`, {
                type: formData.file_adart_terbaru.type,
            });
            data.append('file_adart_terbaru', renamedFile);
        }
        if (formData.file_pengesahan_adart) {
            const renamedFile = new File([formData.file_pengesahan_adart], `${usernamePart}_pengesahan_adart.pdf`, {
                type: formData.file_pengesahan_adart.type,
            });
            data.append('file_pengesahan_adart', renamedFile);
        }

        if (formData.file_surat_haki) {
            const renamedFile = new File([formData.file_surat_haki], `${usernamePart}_surat_haki.pdf`, {
                type: formData.file_surat_haki.type,
            });
            data.append('file_surat_haki', renamedFile);
        }
        if (formData.file_fotokopi_ktp) {
            const renamedFile = new File([formData.file_fotokopi_ktp], `${usernamePart}_fotokopi_ktp.pdf`, {
                type: formData.file_fotokopi_ktp.type,
            });
            data.append('file_fotokopi_ktp', renamedFile);
        }

        if (formData.file_dokumen_legalitas) {
            const renamedFile = new File([formData.file_dokumen_legalitas], `${usernamePart}_dokumen_legalitas.pdf`, {
                type: formData.file_dokumen_legalitas.type,
            });
            data.append('file_dokumen_legalitas', renamedFile);
        }

        if (formData.file_dokumen_validasi) {
            const renamedFile = new File([formData.file_dokumen_validasi], `${usernamePart}_dokumen_validasi.pdf`, {
                type: formData.file_dokumen_validasi.type,
            });
            data.append('file_dokumen_validasi', renamedFile);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/insertFilePerusahaan`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil',
                    text: 'Data perusahaan berhasil disimpan',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
            });
        }
        setLoading(false);
    };
    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <p className="mb-4"><b>Informasi File Data Perusahaan</b></p>
            <Alert key="info" variant="info">Semua file yang di upload harus berformat <b>.pdf</b> dengan max size per file 10Mb</Alert>
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2 mb-0'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>NPWP PERUSAHAAN (SESUAI KANTOR UTAMA)<span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="npwp" value={formData.npwp} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_npwp" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>SPPKP <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="sppkp" value={formData.sppkp}  onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_sppkp" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>
                    </Row>
                </Container>

                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2 mb-0'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>NIB <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="nib" value={formData.nib}  onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_nib" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>
                        {tipe!=1 ?  
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>SURAT HAKI <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="surat_haki" value={formData.surat_haki} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_surat_haki" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col> :
                         <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>SIUP <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="siup" value={formData.siup} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_siup" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>}
                    </Row>
                </Container>

                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2 mb-0'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>KEPESERTAAN BPJS KETENAGAKERJAAN <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="bpjs" value={formData.bpjs} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_bpjs" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>AKTA PENDIRIAN TERBARU <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="akta_pendirian" value={formData.akta_pendirian}  onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_akta_pendirian" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>
                    </Row>
                </Container>

                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2 mb-0'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>PENGESAHAN AKTA PENDIRIAN <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="pengesahan_akta" value={formData.pengesahan_akta} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_pengesahan_akta" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>
                          
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>AD/ART TERBARU <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="adart_terbaru" value={formData.adart_terbaru} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_adart_terbaru" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col> 
                    </Row>
                </Container>

                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2 mb-0'>
                    <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>FOTOKOPI KTP PERWAKILAN PERUSAHAAN <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="fotokopi_ktp" value={formData.fotokopi_ktp} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_fotokopi_ktp"onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>
                        {tipe==1 ?
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>PENGESAHAAN AD/ART <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="text" placeholder="" style={{ width: '95%' }} className="mb-2" name="pengesahan_adart" value={formData.pengesahan_adart} onChange={handleChange} required disabled={disabledForm}/>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_pengesahan_adart"onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col>: ''}
                    </Row>
                </Container>
                           
                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2 mb-0'>
                    {tipe==2 ?    
                    <><Col xs={12} className="px-0">
                    <p className="mb-4"><b>Aspek Legalitas</b></p>
                        </Col>
                    <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>DOKUMEN VALIDASI STANDARISASI KESISTEMAN MITRA MODERN </span>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_dokumen_validasi"onChange={handleChange} disabled={disabledForm}/>
                        </Col>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>DOKUMEN LEGALITAS/KERJAASMA, JIKA MENUNJUK PIHAK KETIGA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_dokumen_legalitas"onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                        </Col></>
                        : ''}
                        <Col xs={12} className="pt-4 px-4 text-end">
                            <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
                
                {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}
            </Form>
        </div>
    )
}

export default FormFilePerusahaan
